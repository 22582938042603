import AccessTimeIcon from '@mui/icons-material/AccessTime';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import PasswordIcon from '@mui/icons-material/Password';
import DnsIcon from '@mui/icons-material/Dns';
import PeopleIcon from '@mui/icons-material/People';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import BusinessIcon from '@mui/icons-material/Business';
import { ItemIcon } from './ItemIcon';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MenuBookIcon from '@mui/icons-material/MenuBook';

/* Application constants */
export const AppConstants = {
    // Base Constants
    AppName: 'Next Solutions Group',
    AppNameShort: 'NSG',

    // Authentication 
    TenantId: 'ef1dde4a-379b-46db-8296-ab69eff67cba',
    ClientId: '1e383796-cb78-4da5-accb-ff59f082d955',
    RedirectURI: "/",

    // Roles
    AdminRole: "Admin",
    UserRole: "User",

    // Routing
    UserLinks: [
        {title: 'Timesheet', href: "/timesheet", tooltip: "NSG Timesheets", icon: <AccessTimeIcon />},
        {title: "OneDrive", href: "https://nextsolutionsgroup-my.sharepoint.com/", external: true, tooltip:"NSG OneDrive", icon: <ItemIcon icon={"onedrive"} alt="One Drive" /> },
        {title: "SharePoint", href: "https://nextsolutionsgroup.sharepoint.com/sites/NSGAll", tooltip:"NSG All SharePoint", external: true, icon: <ItemIcon icon={"sharepoint"} alt="SharePoint" /> },
        {title: "Outlook", href: "https://outlook.office.com/mail/", external: true, tooltip:"Outlook Email", icon: <ItemIcon icon={"outlook"} alt="Outlook" /> },
        {title: "Teams", href: "https://teams.microsoft.com/", external: true, tooltip:"Teams", icon: <ItemIcon icon={"teams"} alt="Teams" /> },
        {title: "Employee Handbook", href: "https://nextsolutionsgroup.sharepoint.com/:b:/r/sites/NSGAll/Shared%20Documents/05.%20Research%20%26%20Development/Documents/NSG%20Employee%20Handbook%20-%20March%202025.pdf", external: true, tooltip:"Employee Handbook", icon: <MenuBookIcon  />}
    ],

    UserToolLinks: [
        {title: 'QR Generator', href: "/tools/qrgenerator", icon: <QrCode2Icon />, tooltip: "QR Code Generator"},
        //{title: "Minivan AI", href: "/tools/minivan-ai", icon: <AutoAwesome />,tooltip: "Minivan AI Assistant", disabled: true},
        //{title: 'File Sharing', href: "/tools/filesharing", icon: <DriveFileMove />, tooltip: "NSG File Sharing"},
    ],

    AdminToolLinks: [
        {title: 'Password Check', href: "/tools/password", icon: <PasswordIcon />, tooltip: "Password Breach Search"},
        {title: 'IP Lookup', href: "/tools/iplookup", tooltip: "IP Information", icon: <img src={process.env.PUBLIC_URL + '/icons/ip.webp'} style={{width: '25px', fill: '#fff'}} alt="ip" />},
        {title: 'Domain Lookup', href: "/tools/domainlookup", tooltip: "DNS Information", icon: <DnsIcon />},
        {title: 'Phone Lookup', href: "/tools/phonelookup", tooltip: "Phone Operator Information" , icon: <LocalPhoneIcon />},
    ],

    AdminPageLinks: [
        {title: "Clients", href: "/admin/clients", icon: <BusinessIcon />},
        {title: "Reports", href: "/admin/reports", icon: <AssessmentIcon />},
        {title: "Users", href: "/admin/users", icon: <PeopleIcon />},
        {title: "Analytics", href: "/admin/analytics", icon: <AutoGraphIcon />},
        //{title: "Logs", href: "/admin/logs", todo: true},
        //{title: "Inventory", href: "/admin/inventory", todo: true},
        //{title: "Security", href: "/admin/security", todo: true},
    ]
}

